.post-card {
  width: 512px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 2rem;

  .post-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    .post-card-user-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      text-decoration: none;

      img.post-card-user-avatar {
        width: 1.75rem;
        aspect-ratio: 1/1;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }

      .post-card-title {
        flex-grow: 1;
        font-size: 0.75rem;
        display: inline-flex;
        gap: 0.35rem;

        .username {
          color: black;
          font-weight: 600;
        }
        .post-time {
          color: #9e9e9e;
        }
      }
    }
  }

  .post-card-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .post-image {
      width: 100%;
      border-radius: 0.35rem;
    }

    .post-actions {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid #bcbcbc;
    }

    .post-like-count {
      font-size: 0.75rem;
      font-weight: 600;
    }

    .post-captions {
      font-size: 0.75rem;
      font-weight: 400;
      white-space: pre-wrap;
      margin-block: 0;

      span.username {
        font-weight: 600;
        margin-right: 0.35rem;
      }
    }

    .post-comments {
      display: flex;
      flex-direction: column;
      gap: 0.15rem;

      .post-view-all-comments {
        font-size: 0.75rem;
        font-weight: 400;
        color: #9e9e9e;
      }
  
      .post-add-comment {
        font-size: 0.75rem;
        font-weight: 400;
        color: #9e9e9e;
      }
    }
  }
}