// font family
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// variables
@import 'src/styles/variables.scss';

// component styles
@import 'src/styles/components/sidebar.scss';
@import 'src/styles/components/post-card.scss';

// layout styles
@import 'src/styles/layouts/default-layout.scss';

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.posts-list {
  padding: 1.5rem 0 1.5rem 8rem;
}

.profile-page {
  padding: 1.5rem 0 1.5rem 8rem;

  .profile-content {
    width: 650px;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;

    .profile-info {
      display: flex;
      align-items: center;
      gap: 2rem;
      padding-bottom: 3rem;

      img.avatar {
        width: 8rem;
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }

      .profile-details {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .profile-main-info {
          display: flex;
          align-items: center;
          gap: 1rem;

          .username {
            font-size: 1.25rem;
            font-weight: 600;
          }

          button.edit-profile {
            background-color: #EFEFEF;
            color: black;
            border-radius: 0.5rem;
            padding: 0.25rem 0.75rem;
            font-size: 1rem;
            font-weight: 600;
            border: 0;
            cursor: pointer;
          }
        }

        .fullname {
          font-size: 1rem;
          font-weight: 600;
        }

        .bio {
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }

    .profile-nav {
      display: flex;
      justify-content: center;
      border-top: 1px solid #cecece;

      .nav-menu {
        border-top: 1px solid transparent;
        padding-top: 0.5rem;
        font-size: 0.75rem;
        cursor: pointer;

        &.active {
          border-top: 2px solid black;
          color: black;
          font-weight: 600;
        }

        &-content {
          display: inline-flex;
          align-items: center;
          gap: 0.5rem
        }
      }
    }

    .profile-posts-list {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 0.1rem;

      img.post-thumbnail {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
      }
    }
  }
}

.login-page {
  width: 100dvw;
  height: 100dvh;
  display: grid;
  place-items: center;

  .login-container {
    width: 20rem;
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 0.75rem;

    form.login-form {
      margin-top: auto;
      border: 1px solid #cecece;
      padding: 1.75rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      .title {
        font-size: 2rem;
        font-weight: 400;
        width: 100%;
        text-align: center;
      }

      .fields-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.5rem;

        input {
          padding: 0.5rem;
          border: 1px solid #cecece;
          background-color: #fafafa;
        }

        button[type=submit] {
          background-color: $primary-color;
          padding: 0.5rem 0.25rem;
          border: 0;
          border-radius: 0.25rem;
          color: white;
          cursor: pointer;
          font-size: 0.85rem;
          font-weight: 600;
          text-align: center;

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }

      .forgot-password {
        text-align: center;
        font-size: 0.75rem;
        color: #385898;
      }
    }

    .login-register-section {
      border: 1px solid #cecece;
      padding: 0.75rem;
      text-align: center;
      font-size: 0.75rem;

      .sign-up {
        color: $primary-color;
        text-decoration: none;
      }
    }

    .login-app-info {
      width: 100%;
      margin-top: 20%;
      margin-bottom: 20%;
      text-align: center;
      font-size: 0.75rem;
    }
  }
}

.register-page {
  width: 100dvw;
  height: 100dvh;
  display: grid;
  place-items: center;

  .register-container {
    width: 20rem;
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 0.75rem;
    padding-top: 1rem;

    form.register-form {
      margin-top: auto;
      border: 1px solid #cecece;
      padding: 1.75rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .title {
        font-size: 2rem;
        font-weight: 400;
        width: 100%;
        text-align: center;
      }

      .description {
        text-align: center;
        width: 100%;
        color: #6F6B6B;
        margin-block: 0;
      }

      .fields-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.5rem;

        input {
          padding: 0.5rem;
          border: 1px solid #cecece;
          background-color: #fafafa;
        }
      }

      .contact-policy-info {
        text-align: center;
        width: 100%;
        color: #6F6B6B;
        font-size: 0.75rem;
      }

      .terms-privacy {
        text-align: center;
        width: 100%;
        color: #6F6B6B;
        font-size: 0.75rem;
      }

      button[type=submit] {
        background-color: $primary-color;
        padding: 0.5rem 0.25rem;
        border: 0;
        border-radius: 0.25rem;
        color: white;
        cursor: pointer;
        font-size: 0.85rem;
        font-weight: 600;
        text-align: center;
        
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }

    .register-register-section {
      border: 1px solid #cecece;
      padding: 0.75rem;
      text-align: center;
      font-size: 0.75rem;

      .log-in {
        color: $primary-color;
        text-decoration: none;
      }
    }

    .register-app-info {
      width: 100%;
      margin-top: 10%;
      margin-bottom: 10%;
      text-align: center;
      font-size: 0.75rem;
    }
  }
}

/* Style the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1000; /* Ensure the modal is on top of other content */
}

/* Style the modal content */
.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001; /* Ensure the modal content is on top of the overlay */
}

.new-post-container {
  width: 50%;
  .new-post-header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.5rem;

    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    button.share-button {
      color: $primary-color;
      font-size: 1rem;
      font-weight: 600;
      border: 0;
      background-color: transparent;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  .new-post-content {
    width: 100%;
    display: flex;
    align-items: stretch;
    gap: 1rem;

    .new-post-media-container {
      width: 60%;
      display: grid;
      place-items: center;

      .new-post-media {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        label {
          background-color: $primary-color;
          padding: 0.25rem 0.75rem;
          border: 0;
          border-radius: 0.25rem;
          color: white;
          cursor: pointer;
          font-size: 0.85rem;
          font-weight: 600;
          text-align: center;
        }
      }
    }

    img.post-image-preview {
      width: 60%;
    }

    .new-post-caption {
      width: 40%;
      border: 1px solid #cecece;
      margin-right: 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding-bottom: 1rem;

      .user-info {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 0.75rem 0 0.75rem;

        img.user-avatar {
          width: 2rem;
          aspect-ratio: 1/1;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }

        .user-name {
          font-weight: 500;
        }
      }

      .caption {
        padding: 0 0.75rem;
        display: flex;
        align-items: stretch;
        flex-direction: column;

        textarea {
          padding: 0.25rem;
          resize: none;
          border: 0;
          font-family: $font-family;

          &::placeholder {
            color: #cecece;
          }
        }
      }

      .location {
        border-top: 1px;
        border-bottom: 1px;
        border-left: 0;
        border-right: 0;
        border-style: solid;
        border-color: #cecece;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        input {
          width: 100%;
          border: 0;
          padding: 0.25rem;
          
          &::placeholder {
            color: #cecece;
          }
        }
      }
    }
  }
}