.sidebar {
  min-width: 16rem;
  max-width: 16rem;
  height: calc(100dvh - 3rem);
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  border-right: 1px solid #cecece;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;

  &-logo {
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: none;
    color: black;
    width: fit-content;
  }

  &-menu {
    height: calc(100dvh - 12rem);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .menu-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      text-decoration: none;
      color: black;

      &:not(:disabled) {
        cursor: pointer;
      }
    }
  }

  &-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;

    button.login-button {
      width: 100%;
      background-color: $primary-color;
      padding: 1rem 0.25rem;
      border: 0;
      border-radius: 0.25rem;
      color: white;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
    }

    .user-info {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      text-decoration: none;
  
      img.avatar {
        width: 2.5rem;
        aspect-ratio: 1/1;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
  
      .username {
        flex-grow: 1;
        font-size: 1rem;
        display: inline-flex;
        gap: 0.35rem;
        color: black;
        font-weight: 600;
      }
    }
  }
}