.default-layout-container {
  width: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
}

.default-layout-scrollable-content {
  width: 100%;
}